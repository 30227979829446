<template>
  <b-field v-if="!isHidden" :label="`Complexity ${required ? '*' : ''}`">
    <b-select
      ref="complexity"
      v-model="field.complexity"
      :disabled="disabled"
      :required="required"
      placeholder="Select site complexity..."
      expanded
      @input="$emit('change', field.complexity)"
    >
      <option disabled>Select site complexity…</option>

      <option
        v-for="option in sortedOptions"
        :key="option.id"
        :value="option.id"
        >{{ option.name }}</option
      >
    </b-select>
  </b-field>
</template>

<script>
const complexities = require("@/data/siteComplexities.json");

export default {
  name: "SiteComplexityField",
  props: {
    complexity: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isHidden: false,
      field: {
        complexity: this.complexity
      }
    };
  },
  computed: {
    sortedOptions() {
      return this.$_.sortBy(complexities, ["order"]);
    }
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.complexity.$el.querySelector("select").focus();
    }
  }
};
</script>
